<mat-card>
  <mat-card-title>
    Takwimu za ugonjwa wa COVID-19 nchini Tanzania
  </mat-card-title>
  <mat-card-subtitle>
    Kwa mujibu wa chuo kikuu cha
    <a href="https://github.com/CSSEGISandData/COVID-19" target="_blank"
      >John Hopkins</a
    >
    mnamo {{ latestDate | date }}
  </mat-card-subtitle>
</mat-card>

<div class="row">
  <div class="col-sm-6">
    <div class="col">
      <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-dark list-title">
          Nchini Tanzania
        </li>
        <li class="list-group-item parent list-group-item-warning">
          <span>Jumla ya Maambukizi</span>
          <span class="fill-remaining-space"></span>
          <span>{{ latestCountryData.Confirmed | json }}</span>
        </li>
        <li class="list-group-item parent list-group-item-danger">
          <span>Jumla ya Vifo</span>
          <span class="fill-remaining-space"></span>
          <span>{{ latestCountryData.Deaths }}</span>
        </li>
        <li class="list-group-item parent list-group-item-primary">
          <span>Jumla Ya Waliopona</span>
          <span class="fill-remaining-space"></span>
          <span>{{ latestCountryData.Recovered }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="col">
      <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-dark list-title">Duniani</li>
        <li class="list-group-item parent list-group-item-warning">
          <span>Jumla ya Maambukizi</span>
          <span class="fill-remaining-space"></span>
          <span>{{ WorldData.TotalConfirmed | json | number }}</span>
        </li>
        <li class="list-group-item parent list-group-item-danger">
          <span>Jumla ya Vifo</span>
          <span class="fill-remaining-space"></span>
          <span>{{ WorldData.TotalDeaths | json | number }}</span>
        </li>
        <li class="list-group-item parent list-group-item-primary">
          <span>Jumla Ya Waliopona</span>
          <span class="fill-remaining-space"></span>
          <span>{{ WorldData.TotalRecovered | json | number }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-sm-12">
    <div id="chartContainer" class="chart-container"></div>
  </div>
</div>
