<div class="main-content">
  <mat-card class="main-card">
    <mat-card-subtitle>
      Kwa maoni, ushauri, dukuduku au suala lolote, usisite kuwasiliana na
      yeyote kutoka katika timu zetu hii ya msaada.
    </mat-card-subtitle>
  </mat-card>

  <div *ngFor="let group of members" class="row">
    <h2>{{ group.group | titlecase }}</h2>
    <mat-divider></mat-divider>
    <mat-card
      *ngFor="let member of group.members; let a = index"
      class="contact-card column"
    >
      <mat-card-header>
        <mat-card-title>{{ member.name }}</mat-card-title>
        <mat-card-subtitle>{{ member.nickName }}</mat-card-subtitle>
        <img
          mat-card-avatar
          class="dp"
          [src]="imageAssetsUrl + member.imageUrl"
        />
      </mat-card-header>
      <mat-divider></mat-divider>
      <span *ngFor="let icon of icons; let i = index">
        <a
          [class]="icon.class"
          [href]="icon.link + member.links[i] + subject[i]"
          target="_blank"
        >
          <i [class]="icon.name"></i>
        </a>
      </span>
    </mat-card>
  </div>
</div>
