<div class="content">
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <a class="navbar-brand">
        <img src="favicon.ico" class="logo navbar-brand" routerLink="/home" />
        <!-- <span class="title navbar-brand" routerLink="/home">{{ title }}</span> -->
      </a>

      <div class="navbar-toggler">
        <button
          class="icon-button btn btn-outline-dark"
          *ngFor="let button of buttons"
          [routerLink]="button.link"
          routerLinkActive="active-button"
        >
          <i [class]="button.icon"></i>
        </button>
      </div>

      <!-- <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <span class="title navbar-brand" routerLink="/home">{{ title }}</span>
        <span class="fill-remaining-space"></span>
        <div class="buttons-container">
          <span class="sr-only">(current)</span>
          <button
            type="button"
            class="button btn btn-outline-dark"
            data-toggle="collapse"
            data-target="#navbarNav"
            *ngFor="let button of buttons"
            [routerLink]="button.link"
            routerLinkActive="active-button"
          >
            {{ button.label }}
          </button>
        </div>
      </div>
    </nav>
  </div>

  <div class="outlet-container">
    <div></div>
    <router-outlet></router-outlet>
  </div>
  <!-- Share modal -->
  <div class="share-container">
    <button
      type="button "
      class="btn share-button blink-2"
      data-toggle="modal"
      data-target=".bd-example-modal-xl"
    >
      <i class="fa fa-share-alt"></i>&nbsp;Shirikisha Marafiki
    </button>
  </div>

  <div
    class="modal fade bd-example-modal-xl"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myExtraLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLongTitle">
            Shirikisha Marafiki
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="list-group">
            <a
              href="whatsapp:/send?text=Takwimu%20na%20Taarifa%20muhimu%20kuhusu%20ugonjwa%20wa%20COVID-19%20kwenye%20dashbodi%20moja%20https://dashbodi.netlify.app"
              target="_blank"
              class="list-group-item list-group-item-dark"
            >
              <i class="fa fa-whatsapp whatsapp"></i> &nbsp; &nbsp; Whatsapp
            </a>
            <a
              href="https://www.twitter.com/share?text=Takwimu%20na%20Taarifa%20muhimu%20kuhusu%20ugonjwa%20wa%20COVID-19%20kwenye%20dashbodi%20moja&url=https://dashbodi.netlify.app"
              target="_blank"
              class="list-group-item list-group-item-dark"
            >
              <i class="fa fa-twitter twitter"></i> &nbsp; &nbsp; Twitter
            </a>
            <a
              href="https://www.facebook.com/sharer/sharer.php?t=Takwimu%20na%20Taarifa%20muhimu%20kuhusu%20ugonjwa%20wa%20COVID-19%20kwenye%20dashbodi%20moja&u=https://dashbodi.netlify.app"
              target="_blank"
              class="list-group-item list-group-item-dark"
            >
              <i class="fa fa-facebook facebook"></i> &nbsp; &nbsp; Facebook
            </a>
            <a
              href="https://www.linkedin.com/shareArticle?title=Takwimu%20na%20Taarifa%20muhimu%20kuhusu%20ugonjwa%20wa%20COVID-19%20kwenye%20dashbodi%20moja&url=https://dashbodi.netlify.app"
              target="_blank"
              class="list-group-item list-group-item-dark"
            >
              <i class="fa fa-linkedin linkedin"></i> &nbsp; &nbsp; Linkedin
            </a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Ghairi
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Emergency modal -->
  <div class="emergency-container">
    <button
      type="button"
      class="btn btn-primary emergency-button blink"
      data-toggle="modal"
      data-target=".bd-example-modal-lg"
    >
      <i class="fa fa-ambulance"></i> Huduma za Dharura
    </button>
  </div>

  <div
    class="modal fade bd-example-modal-lg"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLongTitle">
            Huduma za Dharura
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Ili kuripoti dharura yoyote nchini Tanzania
          <img src="../assets/tzflag.gif" /> piga simu ya BURE kwenda miongoni
          mwa namba hizi
          <div class="list-group">
            <a href="tel:199" class="list-group-item list-group-item-dark">
              <i class="fa fa-phone"></i>199 &nbsp; &nbsp; Simu ya Dharura -
              Bure
            </a>
            <a href="tel:*199%23" class="list-group-item list-group-item-dark"
              ><i class="fa fa-phone"></i>*199# &nbsp; Simu ya Dharura - Bure</a
            >
          </div>
          Au tembelea
          <a href="https://moh.go.tz/sw/" target="_blank"
            >tuvuti ya Wizara ya Afya, Maendeleo ya Jamii, Jinsia Wazee na
            watoto</a
          >
          kwa maelezo zaidi.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Ghairi
          </button>
        </div>
      </div>
    </div>
  </div>

  <a class="footer-link" href="https://github.com/mrblack360" target="_blank">
    <div class="footer">
      Maswi, M. R.
      {{ footer }}
    </div>
  </a>
</div>
