<div class="main-content">
  <mat-card class="main-card">
    <h3>Karibu Dashboard!</h3>
    <p>
      Dashboard imejenga teknolojia inayowezesha Watanzania na watu wote duniani
      kupata taarifa za uhakika kuhusiana na ugonjwa wa COVID-19 zilizohaririwa
      na kuwekwa katika mfumo rahisi unaoeleweka na kufahamika kwa urahisi
      zaidi.
    </p>
    <p>
      Hatutozi gharama zozote katika matumizi ya Dashboard. Lakini,
      tutakuonyesha matangazo ambayo tunahisi utayapenda na kuyafurahia kwa muda
      ambao utakua ukiendelea kutumia Dashboard.
    </p>
    <p>
      Hatuchukui wala kutunza taarifa zako binafsi. Hivyo hatutoi taarifa zako
      kwa watoa matangazo kama vile jina, email au mawasiliano yako yatakayo
      kutambulisha binafsi.
    </p>
    <p>
      Taarifa zote zilizochapishwa katika Dashboard zimetoka katika vyombo
      makini vya kuaminika kama vilivyotajwa katika kila taarifa husika. Hata
      hivyo, Dashboard au/na washirika wake hawahusiki kwa namna yoyote ile na
      ithibati ya taarifa yoyote itakayoonekana katika Dashboard.
    </p>
    <p>Relaaax! COVID-19 ipo, Jikinge na Uwakinge uwapendao.</p>
  </mat-card>
</div>
